<template>
	<svg
		id="header__logo"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 244.6 57.3"
		enable-background="new 0 0 244.6 57.3"
		xml:space="preserve"
	>
		<g>
			<g>
				<path d="M1.5,1.4v54.5H232V1.4H1.5z" />
			</g>
			<g>
				<path
					d="M235,2.3h0.9v4c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-4h0.9c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4H235
			c-0.2,0-0.4,0.2-0.4,0.4C234.5,2.1,234.7,2.3,235,2.3z"
				/>
				<path
					d="M239,6.7c0.2,0,0.4-0.2,0.4-0.4V3.2l1.2,1.6c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l1.2-1.6v3.1
			c0,0.2,0.2,0.4,0.4,0.4c0.3,0,0.4-0.2,0.4-0.4V1.8c0-0.2-0.2-0.4-0.4-0.4c-0.1,0-0.3,0.1-0.3,0.2L241,3.7l-1.6-2.2
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3,0-0.4,0.2-0.4,0.4v4.4C238.5,6.5,238.7,6.7,239,6.7z"
				/>
			</g>
		</g>
		<g>
			<path
				fill="#FFFFFF"
				d="M65.1,28.5c0-2.2-1.1-5.1-2.5-6.8c-1.7-2.2-4.3-3.4-7.1-3.4c-5.6,0-9.9,4.7-9.9,10.9
		c0,6.3,4.3,10.7,10.5,10.7c3,0,6.1-1.1,7.9-2.8c0.5-0.5,0.9-1.2,0.9-1.8c0-1.2-0.9-2-2.1-2c-0.6,0-1,0.2-2.2,1
		c-1.4,0.9-2.7,1.3-4.1,1.3c-1.9,0-3.6-0.8-4.4-2.2c-0.3-0.6-0.7-1.5-1-2.6H63C64.5,30.7,65.1,30.1,65.1,28.5z M50.7,27.3
		c0.5-3.3,2.2-5.1,4.7-5.1s4.1,1.7,4.6,5.1H50.7z"
			/>
			<path
				fill="#FFFFFF"
				d="M133.2,35.6c-0.5,0-1.1-0.1-1.7-0.3c-0.8-0.2-1.2-0.5-2.5-1.3c-0.8-0.5-1.4-0.7-1.9-0.7
		c-1.1,0-2.1,1-2.1,2.2c0,1,0.9,2.1,2.6,2.9c1.7,0.9,3.4,1.3,5.3,1.3c4.6,0,8-2.7,8-6.4c0-1.8-1-3.5-2.7-4.6c-1-0.7-2.1-1.2-4.1-1.9
		c-1.7-0.7-2.1-0.9-2.7-1.3c-0.7-0.4-0.8-0.7-0.8-1.3c0-1,1-1.8,2.4-1.8c0.9,0,2,0.3,3,0.8l1,0.5c0.5,0.2,1,0.3,1.3,0.3
		c1.1,0,2-0.9,2-2c0-2-3.3-3.8-7.1-3.8c-4.4,0-7.6,2.7-7.6,6.3c0,1.6,0.7,3.1,1.9,3.9c0.9,0.6,2.2,1.2,4.1,1.9l1.7,0.7
		c1.8,0.7,2.7,1.4,2.7,2.3C136.1,34.6,134.8,35.6,133.2,35.6z"
			/>
			<path
				fill="#FFFFFF"
				d="M103.7,39.7c1.4,0,2.5-1,2.5-2.4V11.7c0-1.4-1.1-2.5-2.4-2.5c-1.4,0-2.5,1.2-2.5,2.5v9
		c-1.9-1.9-3-2.4-5.2-2.4c-5.3,0-9.3,4.6-9.3,10.7c0,6,4,10.7,9.2,10.7c2.5,0,3.6-0.5,5.5-2.8v0.5C101.3,38.7,102.4,39.7,103.7,39.7
		z M96.4,35.2c-2.7,0-4.7-2.5-4.7-6.2c0-3.6,2-6.2,4.8-6.2c2.9,0,4.7,2.5,4.7,6.4C101.3,32.8,99.4,35.2,96.4,35.2z"
			/>
			<path
				fill="#FFFFFF"
				d="M85.7,28.5c0-2.2-1.1-5.1-2.5-6.8c-1.7-2.2-4.3-3.4-7.1-3.4c-5.6,0-9.9,4.7-9.9,10.9
		c0,6.3,4.3,10.7,10.5,10.7c3,0,6.1-1.1,7.9-2.8c0.5-0.5,0.8-1.2,0.8-1.8c0-1.2-0.8-2-2.1-2c-0.6,0-1,0.2-2.2,1
		c-1.4,0.9-2.7,1.3-4.1,1.3c-1.9,0-3.6-0.8-4.4-2.2c-0.3-0.6-0.7-1.5-1-2.6h11.9C85.1,30.7,85.7,30.1,85.7,28.5z M71.4,27.3
		c0.5-3.3,2.2-5.1,4.7-5.1s4.1,1.7,4.6,5.1H71.4z"
			/>
			<path
				fill="#FFFFFF"
				d="M43.5,18.3c-1.2,0-2.1,0.7-2.4,1.9l-3.7,12l-3.7-11.5c-0.5-1.7-1.2-2.4-2.7-2.4c-1.2,0-2,0.7-2.5,2.4
		l-3.7,11.5l-3.7-12c-0.4-1.2-1.4-1.9-2.5-1.9c-1.4,0-2.4,1.1-2.4,2.5c0,0.3,0.1,0.7,0.2,1l5.5,15.4c0.6,1.7,1.6,2.5,3,2.5
		s2.2-0.7,2.7-2.3l3.5-10.7l3.5,10.7c0.5,1.6,1.4,2.3,2.7,2.3c1.5,0,2.4-0.8,3.1-2.5l5.5-15.4c0.1-0.3,0.2-0.6,0.2-1
		C46,19.4,44.9,18.3,43.5,18.3z"
			/>
			<path
				fill="#FFFFFF"
				d="M147.6,37.2c0-1.6-1.2-2.9-2.8-2.9c-1.5,0-2.9,1.3-2.9,2.9c0,1.5,1.3,2.8,2.9,2.8
		C146.4,39.9,147.6,38.7,147.6,37.2z"
			/>
			<path
				fill="#FFFFFF"
				d="M159.1,22.8c0.8,0,1.6,0.2,2.7,0.7c0.8,0.3,1,0.4,1.4,0.4c1.2,0,2.1-1,2.1-2.4c0-1.7-2.7-3.2-6.3-3.2
		c-5.9,0-10.1,4.5-10.1,10.8s4.1,10.7,10,10.7c3.6,0,6.5-1.5,6.5-3.5c0-1.2-1-2.2-2-2.2c-0.2,0-0.6,0.1-1.1,0.2l-0.9,0.3
		c-1.2,0.4-1.5,0.5-2.2,0.5c-3,0-5.1-2.5-5.1-6.2C154,25.3,156,22.8,159.1,22.8z"
			/>
			<path
				fill="#FFFFFF"
				d="M122.8,18.3c-1.1,0-2,0.7-2.4,1.9l-4.1,11.7l-4.2-11.7c-0.4-1.2-1.3-1.9-2.4-1.9c-1.4,0-2.5,1-2.5,2.5
		c0,0.3,0,0.6,0.1,0.9l6.4,16.5l-2.7,6.6c-0.2,0.3-0.2,0.7-0.2,1.1c0,1.3,1,2.3,2.4,2.3c1.1,0,1.9-0.6,2.4-1.9l9.5-24.4
		c0.1-0.3,0.2-0.6,0.2-1C125.3,19.4,124.2,18.3,122.8,18.3z"
			/>
			<path
				fill="#FFFFFF"
				d="M185.7,29c0-6.1-4.2-10.8-9.8-10.8c-5.5,0-9.8,4.7-9.8,10.7c0,6.1,4.2,10.9,9.8,10.9
		C181.5,39.8,185.7,35.1,185.7,29z M171.1,29c0-3.7,2-6.3,4.8-6.3c2.9,0,4.8,2.6,4.8,6.3c0,3.8-1.9,6.4-4.8,6.4
		C173.1,35.4,171.1,32.8,171.1,29z"
			/>
			<path
				fill="#FFFFFF"
				d="M190.3,18.3c-1.4,0-2.5,1-2.5,2.1v16.8c0,1.4,1.1,2.5,2.5,2.5c1.3,0,2.4-1.1,2.4-2.5V26
		c0-2,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7v11.2c0,1.4,1.1,2.5,2.4,2.5c1.4,0,2.5-1.1,2.5-2.5V26.1c0-2,1.8-3.6,3.9-3.6
		c1.9,0,3.4,1.6,3.4,3.6v11.2c0,1.4,1.2,2.5,2.5,2.5s2.4-1.1,2.4-2.5V25.4c0-4.3-2.9-7.2-7.3-7.2c-2,0-4,0.7-5.3,1.9
		c-0.3,0.2-0.7,0.6-1.2,1.2c-1-1.5-1.6-1.9-3-2.5c-0.9-0.4-1.9-0.5-2.8-0.5c-1.7,0-3.7,0.7-4.6,1.5c-0.2,0.2-0.4,0.5-0.7,0.9v-0.3
		C192.5,19.3,191.5,18.3,190.3,18.3z"
			/>
		</g>
	</svg>
</template>
